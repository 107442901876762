<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="820"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        x-small
        :color="'cyan darken-2'"
        class="mr-2"
        dark
        v-on="on"
        v-bind="attrs"
        :text="item.permiso_de_ausencia == 1 ? false : true"
        @click="fnShowItem()"
      >
        Ausencia
      </v-btn>
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar color="primary" dark :title="item.id">
          Mostrar este registro de inicio de labores con permiso de
          ausencia</v-toolbar
        >
        <v-card-text>
          <v-row class="mt-5">
            <v-col>
              <table>
                <tbody>
                  <!-- <tr>
                    <td>Fecha:</td>
                    <td>Guatemala, {{ fecha_hoy }}</td>
                  </tr> -->
                  <tr>
                    <td>Supervisora:</td>
                    <td>
                      <strong
                        ><v-icon small>mdi-cellphone</v-icon
                        >{{ item.supervisora.alias }},
                        {{ item.supervisora.nombre_completo }}</strong
                      >
                    </td>
                  </tr>

                  <tr>
                    <td>Division:</td>
                    <td>
                      <strong>{{ item.supervisora.division }}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Región:</td>
                    <td>
                      <strong>{{ item.supervisora.region }}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="mt-2">
                <tbody>
                  <tr class="">
                    <td>Agente de comercio:</td>
                    <td>
                      <strong>{{ item.supervisora.tipo }}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
            <v-col v-if="item.permiso_de_ausencia == 1">
              <table>
                <tbody>
                  <tr>
                    <td>Estado: <strong>Con permiso de faltar</strong></td>
                  </tr>

                  <tr></tr>
                  <tr></tr>
                  <tr></tr>
                  <tr></tr>
                  <tr>
                    <td>
                      Permiso ingresado por:
                      <strong
                        >{{ item.usuario.alias }} -
                        {{ item.usuario.nombre_completo }}</strong
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      El día:
                      <strong>
                        {{ getDate(item.permiso_de_ausencia_fecha) }}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          class="justify-end"
          v-if="item.permiso_de_ausencia == 1"
        >
          <v-btn text @click="dialog.value = false">Cerrar ventana</v-btn>
        </v-card-actions>
        <v-card-actions
          class="justify-end"
          v-if="item.permiso_de_ausencia != 1"
        >
          <v-btn
            title="Cambiar el estado de este inicio de labores a: con permiso de faltar"
            text
            @click="fn_PermisoDeAusencia(item.id)"
            color="green"
            >Asignar inicio de labores con permiso de faltar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="dialog.value = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
import moment from 'moment';
require('moment/locale/es.js');
import { mapActions } from 'vuex';
export default {
  name: 'dialogPermisoDeAusencia',
  props: ['item'],
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    getDate(date) {
      return date ? moment(date).utc().format('DD MMM  HH:mm a') : '';
    },
    fnShowItem() {
      console.log('item', this.item);
    },
    fn_PermisoDeAusencia() {
      console.log('dialogPermisoDeAusencia', this.item);

      console.log(moment().format('YYYY-MM-DD HH:mm:ss:SSS +00:00'));

      this.dialog = false;
      this.patchProspecto([
        this.item.id,
        {
          permiso_de_ausencia: 1,
          permiso_de_ausencia_usuario_id:
            this.$store.state.auth.payload.usuario.id,
          permiso_de_ausencia_fecha: moment().format(
            'YYYY-MM-DD HH:mm:ss:SSS +00:00'
          ),
        },
        {},
      ])
        .then((result) => {
          console.log(result);
          this.$notify({
            type: 'success',
            group: 'foo',
            title: `Inicio de labores con permiso de faltar`,
            text: ``,
          });
        })
        .catch((r) => {
          console.log(r);
        });
    },
    ...mapActions('SupervInicioDeLabores', {
      patchProspecto: 'patch',
    }),
  },
  computed: {
    fecha_hoy() {
      return moment().format('DD-MM-YYYY');
    },
  },
};
</script>
