<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card class="" elevation="" max-width="">
          <v-card-text>
            <p class=""></p>
            <div class="text--primary">
              Total de inicios de labores
              {{ tbl_total }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- /** tabla y parametros */ -->
    <v-row>
      <v-col cols="9">
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="tbl_items"
              :items-per-page="5"
              class="elevation-3"
              disable-pagination
              :options.sync="options"
              :hide-default-footer="true"
              :item-key="tbl_items.id"
            >
              <template v-slot:[`item.observaciones`]="{ item }">
                <span
                  v-if="item.observaciones == '' || item.observaciones == null"
                >
                  ---
                </span>
                <span
                  class="d-inline-block text-truncate"
                  style="max-width: 100px"
                  v-else
                >
                  {{ item.observaciones }}
                </span>
              </template>
              <!-- fecha en tabla -->
              <template v-slot:[`item.supervisora.nombre_completo`]="{ item }">
                <span
                  style="white-space: nowrap"
                  :title="`Usuario ${item.supervisora.nombre_completo}`"
                  ><v-icon small>mdi-cellphone</v-icon
                  >{{ item.supervisora.alias }}</span
                >
              </template>

              <template v-slot:[`item.supervisora.division`]="{ item }">
                <span style="white-space: nowrap">{{
                  item.supervisora.division
                }}</span>
              </template>

              <template v-slot:[`item.fecha_inicio`]="{ item }">
                <div v-if="item.permiso_de_ausencia == 1">
                  <strong>Con permiso de faltar</strong>
                </div>
                <div v-else>
                  <strong v-if="item.fecha_inicio">{{
                    getDate(item.fecha_inicio)
                  }}</strong>
                  <span v-else class="text--disabled">
                    Sin inicio de labores manual</span
                  >
                </div>
              </template>
              <template v-slot:[`item.inic_lab_fecha`]="{ item }">
                <div v-if="item.permiso_de_ausencia == 1">
                  <span>---</span>
                </div>
                <div v-else>
                  <span v-if="item.inic_lab_fecha">{{
                    getDate(item.inic_lab_fecha)
                  }}</span>
                  <span
                    v-else
                    class="text--disabled"
                    title="información no disponible"
                  >
                    ---</span
                  >
                </div>
              </template>
              <template v-slot:[`item.fecha_fin`]="{ item }">
                <div v-if="item.permiso_de_ausencia == 1">
                  <span>---</span>
                </div>
                <div v-else>
                  <span v-if="item.fecha_fin">{{
                    getDate(item.fecha_fin)
                  }}</span>
                  <span
                    v-else
                    class="text--disabled"
                    title="información no disponible"
                  >
                    ---</span
                  >
                </div>
              </template>
              <!-- fecha en tabla -->
              <template v-slot:[`item.ver`]="{ item }">
                <iniciolaboresDialogPermisoDeAusencia
                  :item="item"
                ></iniciolaboresDialogPermisoDeAusencia>
                <v-btn
                  x-small
                  elevation="1"
                  color="primary"
                  @click="
                    (dialog = !dialog),
                      (dialogItem = item),
                      item.lat
                        ? (prop_center = {
                            lat: item.lat,
                            lng: item.lng,
                            zoom: 17,
                          })
                        : (prop_center = {
                            lat: item.inic_lab_lat,
                            lng: item.inic_lab_lng,
                            zoom: 17,
                          })
                  "
                  >ver</v-btn
                >
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12"> </v-col>
          <v-col>
            <v-pagination
              class="float-right"
              v-model="page"
              :length="totalPages"
              total-visible="7"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
              @input="handlePageChange"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3">
        <v-card class="" elevation="" max-width="">
          <v-card-title> Parámetros de búsqueda </v-card-title>
          <v-card-text>
            <!-- INICIOS DE LABORES CON PERMISO DE AUSENCIA -->
            <v-checkbox
              v-model="search_PermisoDeAusencia"
              :title="`Mostrar los registros que contienen permiso para faltar`"
              :label="`Registros con permiso para faltar `"
              color="red"
              hide-details
            ></v-checkbox>
            <!-- INICIOS DE LABORES CON PERMISO DE AUSENCIA -->
            <!-- SEARCH || mess -->
            <v-dialog
              ref="mesDialog"
              v-model="modalMes"
              :return-value.sync="dialogVarSelectedMes"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedMes"
                  label="Mes:"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="es"
                v-model="dialogVarSelectedMes"
                scrollable
                type="month"
                show-adjacent-months
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalMes = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.mesDialog.save(dialogVarSelectedMes)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <!-- SEARCH || mess -->

            <!-- /*** Dashboard select - dias */ -->
            <v-dialog
              ref="diaDialog"
              v-model="modalFechas"
              :return-value.sync="dialogVarSelectedDia"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedDias"
                  label="Dias:"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :selected-items-text="'$vuetify.datePicker.itemsSelected'"
                locale="es"
                v-model="dialogVarSelectedDia"
                :range="true"
                scrollable
                :min="dialogVarMinDia"
                :max="dialogVarMaxDia"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalFechas = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.diaDialog.save(dialogVarSelectedDia)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <!-- /*** Dashboard select - dias */ -->
            <!-- DIVISION -->
            <v-autocomplete
              cache-items
              chips
              multiple
              deletable-chips
              :no-data-text="'Sin registros disponibles'"
              clearable
              v-model="searchDivision"
              :items="itemsDivisiones"
              :loading="loadingDivisiones"
              :search-input.sync="searchSyncDivisiones"
              color="primary"
              :hide-selected="false"
              item-text="division"
              item-value="division"
              label="Divisiones"
              placeholder="búscar..."
              prepend-icon="mdi-database-search"
            >
            </v-autocomplete>
            <!-- DIVISION -->
            <!-- REGION -->
            <v-autocomplete
              cache-items
              chips
              multiple
              deletable-chips
              :no-data-text="'Sin registros disponibles'"
              clearable
              v-model="searchRegion"
              :items="itemsRegiones"
              :loading="loadingRegiones"
              :search-input.sync="searchSyncRegiones"
              color="primary"
              :hide-selected="false"
              item-text="region"
              item-value="region"
              label="Regiones"
              placeholder="búscar..."
              prepend-icon="mdi-database-search"
            ></v-autocomplete>
            <!-- REGION -->
            <!-- SECTOR -->
            <v-autocomplete
              cache-items
              chips
              multiple
              deletable-chips
              :no-data-text="'Sin registros disponibles'"
              clearable
              v-model="searchSector"
              :items="itemsSectores"
              :loading="loadingSectores"
              :search-input.sync="searchSyncSectores"
              color="primary"
              :hide-selected="false"
              item-text="codigo"
              item-value="codigo"
              label="Sectores"
              placeholder="búscar..."
              prepend-icon="mdi-database-search"
            >
            </v-autocomplete>
            <!-- SECTOR -->
            <!-- AGENTE DE COMERCIO -->
            <v-autocomplete
              cache-items
              chips
              multiple
              deletable-chips
              :no-data-text="'Sin registros disponibles'"
              clearable
              v-model="searchAgenteDeComercio"
              :items="$store.state.globalAgentesDeComercio"
              :loading="loadingAgenteDeComercio"
              color="primary"
              label="Agente de comercio"
              placeholder="búscar..."
              prepend-icon="mdi-database-search"
              return-object
            >
            </v-autocomplete>
            <!-- AGENTE DE COMERCIO -->
            <!-- SUPERVISORA -->
            <v-autocomplete
              cache-items
              chips
              multiple
              deletable-chips
              :no-data-text="'Buscar supervisora'"
              clearable
              v-model="searchSupervisora"
              :items="itemsSupervisoras"
              :loading="loadingSupervisoras"
              :search-input.sync="searchSyncSupervisoras"
              color="primary"
              item-text="nombre_completo"
              item-value="id"
              label="Usuario(s)"
              placeholder="búscar..."
              prepend-icon="mdi-database-search"
              return-object
              v-on:keyup.enter="
                fnGetTblItems(), (flag_parametros_aplicados = true), (page = 1)
              "
            >
            </v-autocomplete>
            <!-- SUPERVISORA -->
            <!-- ID DE REGISTRO -->
            <v-autocomplete
              cache-items
              chips
              multiple
              deletable-chips
              :no-data-text="'Sin registros disponibles'"
              clearable
              v-model="searchIds"
              :items="itemsIds"
              :loading="loadingIds"
              :search-input.sync="searchSyncIds"
              color="primary"
              :hide-selected="false"
              item-text="id"
              item-value="id"
              label="IDs de registros"
              placeholder="búscar..."
              prepend-icon="mdi-database-search"
            ></v-autocomplete>
            <!-- ID DE REGISTRO -->

            <!-- COMENTARIO -->
            <v-text-field
              clearable
              v-model="search_Observaciones"
              title="Buscar por alguna palabra contenida en los comentarios"
              label="Comentarios"
              prepend-icon="mdi-chat"
              v-on:keyup.enter="
                fnGetTblItems(), (flag_parametros_aplicados = true), (page = 1)
              "
            ></v-text-field>
            <!-- COMENTARIO -->

            <!-- APLICAR PARAMETROS -->
            <v-btn
              id="Buscar parametros"
              @click="
                fnGetTblItems(), (flag_parametros_aplicados = true), (page = 1)
              "
              small
              :class="[
                flag_parametros_aplicados
                  ? 'blue-grey white--text float-right'
                  : 'primary white--text float-right',
              ]"
            >
              Aplicar parametros
              <v-icon right dark> mdi-magnify </v-icon>
            </v-btn>
            <!-- APLICAR PARAMETROS -->
          </v-card-text>
          <v-card-actions>
            <v-btn text color=""> </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- /** tabla y parametros */ -->
    <!-- DIALOG INFO - INICIO DE LABORES -->
    <v-dialog
      v-model="dialog"
      max-width="90%"
      persistent
      max-height="100%"
      scrollable
    >
      <v-card v-if="dialogItem.supervisora">
        <v-card-title class="text-h6" :title="`ID ${dialogItem.id}`">
          Usuario: {{ dialogItem.supervisora.alias }} ({{
            dialogItem.supervisora.nombre_completo
          }})
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-divider class="grey lighten-2 mb-2"></v-divider>
              <div class="text--disabled">
                Coordenadas de inicio de labores registrado por supervisora:
                <v-icon :class="fnGetColor('b')">mdi-label-variant</v-icon
                ><v-btn
                  :disabled="dialogItem.lat ? false : true"
                  @click="
                    prop_center = {
                      lat: dialogItem.lat,
                      lng: dialogItem.lng,
                      zoom: 20,
                    }
                  "
                  small
                  text
                  elevation=""
                  color=""
                  >ir</v-btn
                >
              </div>
              <div class="text--primary">
                Fecha inicio de labores:
                <strong v-if="dialogItem.fecha_inicio">{{
                  getDate(dialogItem.fecha_inicio)
                }}</strong>
                <span v-else class="text--disabled">
                  Sin inicio de labores</span
                >
              </div>

              <div v-if="dialogItem.observaciones" class="text--primary">
                <div class="text--disabled" v-text="'Observaciones:'"></div>

                <p class="ml-2">{{ dialogItem.observaciones }}</p>
              </div>

              <div class="text--primary">
                Latitud:
                <strong v-if="dialogItem.lat">{{ dialogItem.lat }}</strong>
                <span v-else class="text--disabled"> sin información</span>
              </div>
              <div class="text--primary">
                Longitud:
                <strong v-if="dialogItem.lat">{{ dialogItem.lng }}</strong>
                <span v-else class="text--disabled"> sin información</span>
              </div>
              <v-divider class="mt-2 mb-2"></v-divider>
              <div class="text--disabled">
                Coordenadas a las 7:00 am (automático)
                <v-icon :class="fnGetColor('f')">mdi-label-variant</v-icon>
                <v-btn
                  :disabled="dialogItem.inic_lab_lat ? false : true"
                  @click="
                    prop_center = {
                      lat: dialogItem.inic_lab_lat,
                      lng: dialogItem.inic_lab_lng,
                      zoom: 20,
                    }
                  "
                  small
                  text
                  elevation=""
                  color=""
                  >ir</v-btn
                >
              </div>
              <div class="text--primary">
                <!-- automatico -->
                Fecha de inicio de labores :

                <strong v-if="dialogItem.inic_lab_fecha">{{
                  getDate(dialogItem.inic_lab_fecha)
                }}</strong>
                <span v-else class="text--disabled"> sin información</span>
              </div>
              <div class="text--primary">
                Latitud:
                <strong v-if="dialogItem.inic_lab_lat">{{
                  dialogItem.inic_lab_lat
                }}</strong>
                <span v-else class="text--disabled"> sin información</span>
              </div>
              <div class="text--primary">
                Longitud:
                <strong v-if="dialogItem.inic_lab_lng">{{
                  dialogItem.inic_lab_lng
                }}</strong>
                <span v-else class="text--disabled"> sin información</span>
              </div>
              <br />
              <v-divider class="grey lighten-2 mb-2"></v-divider>

              <div class="text--disabled">
                Cierre de labores
                <v-icon :class="fnGetColor('h')">mdi-label-variant</v-icon>
                <v-btn
                  :disabled="dialogItem.lat_fin ? false : true"
                  @click="
                    prop_center = {
                      lat: dialogItem.lat_fin,
                      lng: dialogItem.lng_fin,
                      zoom: 20,
                    }
                  "
                  small
                  text
                  elevation=""
                  color=""
                  >ir</v-btn
                >
              </div>
              <div v-if="dialogItem.fecha_fin" class="text--primary">
                Cierre de labores:
                <strong>{{ getDate(dialogItem.fecha_fin) }}</strong>
              </div>
              <div v-else class="text--primary">
                Cierre de labores:
                <p class="text--disabled">Sin datos de cierre de labores.</p>
              </div>
              <div class="text--primary">
                Latitud:
                <strong v-if="dialogItem.lat_fin">{{
                  dialogItem.lat_fin
                }}</strong>
                <span v-else class="text--disabled"> sin información</span>
              </div>
              <div class="text--primary">
                Longitud:
                <strong v-if="dialogItem.lng_fin">{{
                  dialogItem.lng_fin
                }}</strong>
                <span v-else class="text--disabled"> sin información</span>
              </div>
            </v-col>
            <v-col cols="3" transition="fade-transition">
              <mapa
                :prop_polyline_enable="false"
                :prop_vista_inicio_de_labores="true"
                :prop_markers="[dialogItem]"
                :prop_center="prop_center"
                :prop_otros_tipos_de_visita="true"
              />
            </v-col>
            <v-col cols="3" transition="fade-transition">
              <!-- /**
              
               */ -->
              <zoom-on-hover
                v-if="dialogItem.image_dir"
                :scale="5"
                :img-normal="fnGetVisitaImagen(dialogItem.image_dir)"
                :img-zoom="fnGetVisitaImagen(dialogItem.image_dir)"
              ></zoom-on-hover>
              <!-- /**
               
                */ -->
            </v-col>
            <!-- fin mapa -->
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="
              (dialog = false),
                (prop_center = { lat: 15.5670864, lng: -90.6112826, zoom: 7 })
            "
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG INFO - INICIO DE LABORES -->
  </v-container>
</template>
<script>
import iniciolaboresDialogPermisoDeAusencia from './iniciolaboresDialogPermisoDeAusencia.vue';
import { mapActions } from 'vuex';
import moment from 'moment';
require('moment/locale/es.js');
import mapa from '../components/mapa.vue';
export default {
  name: 'inicio_de_labores',
  components: {
    mapa,
    iniciolaboresDialogPermisoDeAusencia,
  },
  computed: {
    selectedMes() {
      return this.dialogVarSelectedMes
        ? moment(this.dialogVarSelectedMes).format('MMMM-YYYY')
        : moment().format('MMMM-YYYY');
    },
    dialogVarMinDia() {
      if (this.dialogVarSelectedMes) {
        return moment(this.dialogVarSelectedMes)
          .startOf('month')
          .format('YYYY-MM-DD');
      } else {
        return moment().startOf('month').format('YYYY-MM-DD');
      }
    },
    dialogVarMaxDia() {
      if (this.dialogVarSelectedMes) {
        return moment(this.dialogVarSelectedMes)
          .endOf('month')
          .format('YYYY-MM-DD');
      } else {
        return moment().format('YYYY-MM-DD');
      }
    },
    superUserValidation() {
      if (this.$store.state.auth.payload.usuario.sesion_rol == 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      search_PermisoDeAusencia: false,
      search_Observaciones: null,
      //
      prop_center: {},
      //
      flag_parametros_aplicados: true,
      // vacriables de parametros
      //parametros de fechas (modals)
      dialogVarSelectedMes: null,
      dialogVarSelectedDia: null,
      modalMes: false,
      modalFechas: false,
      //
      //supervisoras auntocomplete vars
      itemsSupervisoras: [],
      loadingSupervisoras: false,
      searchSyncSupervisoras: '',
      //itemsAgentesDeComercio: ,
      loadingAgenteDeComercio: false,
      searchAgenteDeComercio: '',
      //sectores auntocomplete vars
      itemsSectores: [],
      loadingSectores: false,
      searchSyncSectores: '',
      //divisiones auntocomplete vars
      itemsDivisiones: [],
      loadingDivisiones: false,
      searchSyncDivisiones: '',
      //regiones auntocomplete vars
      itemsRegiones: [],
      loadingRegiones: false,
      searchSyncRegiones: '',

      //search variables
      searchSupervisora: '',
      searchSector: '',
      searchDivision: '',
      searchRegion: '',

      //

      searchIds: [],
      loadingIds: false,
      itemsIds: [],
      searchSyncIds: '',

      //
      selectedDias: [
        moment().startOf('month').format('DD-MM-YYYY'),
        moment().format('DD-MM-YYYY'),
      ],
      //
      dialogItem: {},
      dialog: false,
      //pagination
      options: {},
      page: 1,
      totalPages: 0,
      pageSize: 5,
      pageSizes: [5, 15, 50],
      //tbl
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Usuario', value: 'supervisora.nombre_completo' },
        { text: 'División', value: 'supervisora.division' },
        { text: 'Región', value: 'supervisora.region' },

        { text: 'Agente de comercio', value: 'supervisora.tipo' },
        { text: 'Inicio (Manual)', value: 'fecha_inicio' },
        { text: 'Inicio (Automático)', value: 'inic_lab_fecha' },

        { text: 'Comentario', value: 'observaciones' },
        // { text: 'Cierre', value: 'fecha_fin' },
        { text: '', value: 'ver' },
      ],
      tbl_items: [],
      tbl_total: 0,
      tbl_db_search: '',
    };
  },
  watch: {
    search_Observaciones(newValue) {
      console.log('searchobservaciones nene', newValue);
      if (newValue == null) {
        this.page = 1;
        this.fnGetTblItems();
      } else {
        this.flag_parametros_aplicados = false;
      }
    },
    search_PermisoDeAusencia() {
      this.page = 1;
      this.fnGetTblItems();
    },
    options: {
      handler() {
        console.log('handler actived');
        this.fnGetTblItems();
      },
      deep: true,
    },
    searchSyncSupervisoras(newValue) {
      if (newValue && newValue != '') {
        this.flag_parametros_aplicados = false;
        if (this.searchSupervisora == null) {
          this.loadingSupervisoras = true;
          //levanta la funcion: fnGetAutocompleteSupervisoras
          this.fnGetAutocompleteSupervisoras();
        } else {
          let currentSupervisora = this.searchSupervisora.nombre_completo;
          if (newValue != currentSupervisora) {
            this.loadingSupervisoras = true;
            //levanta la funcion: fnGetAutocompleteSupervisoras
            this.fnGetAutocompleteSupervisoras();
          }
        }
      }
    },
    searchSyncSectores(newValue) {
      if (newValue && newValue != '') {
        this.loadingSectores = true;
        //levanta la funcion: fnGetAutocompleteSectores
        this.fnGetAutocompleteSectores();
      }
    },
    searchSyncDivisiones(newValue) {
      if (newValue && newValue != '') {
        this.loadingDivisiones = true;
        //levanta la funcion: fnGetAutocompleteDivisiones
        this.fnGetAutocompleteDivisiones();
      }
    },
    searchSyncRegiones(newValue) {
      if (newValue && newValue != '') {
        this.loadingRegiones = true;
        //levanta la funcion: fnGetAutocompleteRegiones
        this.fnGetAutocompleteRegiones();
      }
    },
    //
    searchSyncIds(newValue) {
      if (newValue && newValue != '') {
        this.loadingIds = true;
        this.fnGetAutocompleteIds();
      }
    },
    //
    dialogVarSelectedMes(newValue) {
      if (newValue) {
        this.selectedDias = [
          moment(newValue).startOf('month').format('DD-MM-YYYY'),
          moment(newValue).endOf('month').format('DD-MM-YYYY'),
        ];
        this.dialogVarSelectedDia = [
          moment(newValue).startOf('month').format('YYYY-MM-DD'),
          moment(newValue).endOf('month').format('YYYY-MM-DD'),
        ];
        this.flag_parametros_aplicados = false;
      }
    },
    dialogVarSelectedDia(newValue) {
      this.selectedDias = [
        moment(newValue[0]).format('DD-MM-YYYY'),
        moment(newValue[1]).format('DD-MM-YYYY'),
      ];
      this.flag_parametros_aplicados = false;
    },
  },
  methods: {
    ...mapActions('SupervInicioDeLabores', {
      findInicioDeLaboresSupervisoras: 'find',
    }),
    ...mapActions('Usuarios', {
      findUsuarios: 'find',
    }),
    ...mapActions('Consejeras', {
      findConsejeras: 'find',
    }),

    getParams() {
      /**
       * configuración de parametros
       */
      const params = {};
      //fechas para feathers
      ///
      if (this.search_Observaciones != null) {
        params['observaciones'] = {
          $like: '%' + this.search_Observaciones + '%',
        };
      }
      ///
      if (this.search_PermisoDeAusencia == true) {
        params['permiso_de_ausencia'] = 1;
      }
      //
      if (this.searchDivision && this.searchDivision != '') {
        params['division'] = { $in: this.searchDivision };
      }
      if (this.searchRegion && this.searchRegion != '') {
        params['region'] = { $in: this.searchRegion };
      }
      if (this.searchSector && this.searchSector != '') {
        params['codigo_supervisora'] = { $in: this.searchSector };
      }
      // else if (this.$store.state.auth.payload.usuario.sesion_rol != 0) {
      //   params['codigo_supervisora'] = {
      //     $in: this.$store.state.auth.payload.codigos_supervisoras,
      //   };
      // }
      //
      if (this.searchAgenteDeComercio && this.searchAgenteDeComercio != '') {
        params['tipo'] = { $in: this.searchAgenteDeComercio };
      }
      //
      //params['id_usuario'] = { $nin: [1] };

      let split1 = this.selectedDias[0].split('-');
      // prettier-ignore
      let firstDay = new Date(split1[2],split1[1] - 1,split1[0] - 1,23, 59, 59);

      let split2 = this.selectedDias[1].split('-');
      let lastDay = new Date(split2[2], split2[1] - 1, split2[0], 23, 59, 59);

      //fechas para feathers
      if (this.searchIds.length == []) {
        params['$or'] = [
          { fecha_inicio: { $gte: firstDay, $lte: lastDay } },
          { inic_lab_fecha: { $gte: firstDay, $lte: lastDay } },
        ];
      }

      params['$limit'] = this.pageSize;
      params['$skip'] = (this.page - 1) * this.pageSize;
      params['$sort'] = { createdAt: -1 };

      /** parametrización de supervisoras asignadas a usuario administrativo */
      if (this.searchSupervisora && this.searchSupervisora != '') {
        let arrayOfIds = this.searchSupervisora.map((a) => a.id);
        params['id_usuario'] = { $in: arrayOfIds };
      }
      //
      if (this.searchIds.length >= 1) {
        params['id'] = { $in: this.searchIds };
      }

      /**
       *
       * retorno de parametros
       */

      return params;
    },
    handlePageChange(value) {
      this.page = value;
      this.fnGetTblItems();
    },

    fnGetTblItems() {
      this.findInicioDeLaboresSupervisoras({ query: this.getParams() }).then(
        (result) => {
          result.data.forEach((initlab) => {
            initlab.motivo_de_visita_id = 'b';
          });
          this.tbl_items = result.data;
          this.tbl_total = result.total;
          this.totalPages = Math.ceil(
            parseInt(result.total) / parseInt(this.pageSize)
          );
        }
      );
    },
    fnGetVisitaImagen(dir) {
      return process.env.VUE_APP_FEATHERS_SERVER + dir;
    },
    getDate(date) {
      return date ? moment(date).utc().format('DD/MM  HH:mm a') : '';
    },
    fnShowInfoInitLab(item) {
      console.log(item);
    },

    /*** funciones de parametros */
    fnGetAutocompleteSupervisoras() {
      //
      console.log('fnGetAutocompleteSupervisoras');
      this.findUsuarios({
        query: this.getParamasAutoCompleteSupervisoras(),
      }).then((result) => {
        result.data.forEach((supervisora) => {
          supervisora.nombre_completo =
            supervisora.alias +
            ' - ' +
            supervisora.tipo +
            ' - ' +
            supervisora.division +
            ' - ' +
            supervisora.region;
        });
        this.loadingSupervisoras = false;
        this.itemsSupervisoras = result.data;
      });
      //
    },

    getParamasAutoCompleteSupervisoras() {
      /**
       * configuración de parametros
       * envía informacion a los parametros de la funcion: fnGetAutocompleteSupervisoras
       */
      const params = {};
      params['$select'] = [
        'id',
        'nombre_completo',
        'codigo',
        'alias',
        'tipo',
        'division',
        'region',
      ];
      params['region'] = { $ne: '' };
      if (this.searchSyncSupervisoras != '') {
        params['$or'] = [
          {
            codigo: { $like: '%' + this.searchSyncSupervisoras + '%' },
          },
          {
            nombre_completo: { $like: '%' + this.searchSyncSupervisoras + '%' },
          },
          {
            alias: { $like: '%' + this.searchSyncSupervisoras + '%' },
          },
        ];
        if (this.$store.state.auth.payload.usuario.sesion_rol != 0) {
          params['codigo'] = {
            $in: this.$store.state.auth.payload.codigos_supervisoras,
          };
        }
      }
      /**
       *
       * retorno de parametros
       */
      return params;
    },
    //
    fnGetAutocompleteSectores() {
      //
      this.findUsuarios({
        query: this.getParamasAutocompleteSectores(),
      }).then((result) => {
        this.loadingSectores = false;
        this.itemsSectores = result.data;

        console.log(result.data);
      });
      //
    },
    fnGetAutocompleteDivisiones() {
      //
      this.findUsuarios({
        query: this.getParamasAutocompleteDivisiones(),
      }).then((result) => {
        this.loadingDivisiones = false;
        this.itemsDivisiones = result.data;
      });
      //
    },
    fnGetAutocompleteRegiones() {
      //
      this.findUsuarios({
        query: this.getParamasAutocompleteRegiones(),
      }).then((result) => {
        console.log(result.data);
        this.loadingRegiones = false;
        this.itemsRegiones = result.data;
      });
      //
    },
    //
    fnGetAutocompleteIds() {
      //
      this.findInicioDeLaboresSupervisoras({
        query: this.getParamasAutocompleteIds(),
      }).then((result) => {
        console.log(result.data);
        this.loadingIds = false;
        this.itemsIds = result.data;
      });
      //
    },
    getParamasAutocompleteIds() {
      /**
       * configuración de parametros
       * envía informacion a los parametros de la funcion: fnGetAutocompleteRegiones
       */
      const params = {};

      console.log(' le siynchos ', this.searchSyncIds);

      if (this.searchSyncIds != '') {
        params['id'] = this.searchSyncIds;
      }

      /**
       *
       * retorno de parametros
       */
      return params;
    },
    //
    getParamasAutocompleteSectores() {
      /**
       * configuración de parametros
       * envía informacion a los parametros de la funcion: fnGetAutocompleteSectores
       */
      const params = {};
      params['sesion_rol'] = 2;
      params['$select'] = ['codigo'];
      params['$limit'] = 3;
      params['$sort'] = { codigo: 1 };

      if (this.searchSyncSectores != '') {
        params['codigo'] = { $like: '%' + this.searchSyncSectores + '%' };
      }

      /**
       *
       * retorno de parametros
       */
      return params;
    },
    getParamasAutocompleteDivisiones() {
      /**
       * configuración de parametros
       * envía informacion a los parametros de la funcion: fnGetAutocompleteDivisiones
       */
      const params = {};
      params['$select'] = ['division'];
      params['$limit'] = 3;
      params['$sort'] = { division: 0 };

      if (this.searchSyncDivisiones != '') {
        params['division'] = { $like: '%' + this.searchSyncDivisiones + '%' };
      }

      /**
       *
       * retorno de parametros
       */
      return params;
    },
    getParamasAutocompleteRegiones() {
      /**
       * configuración de parametros
       * envía informacion a los parametros de la funcion: fnGetAutocompleteRegiones
       */
      const params = {};
      params['$select'] = ['region'];
      params['$limit'] = 3;
      params['$sort'] = { region: 1 };

      if (this.searchSyncRegiones != '') {
        params['region'] = { $like: this.searchSyncRegiones + '%' };
      }

      /**
       *
       * retorno de parametros
       */
      return params;
    },
    //
    fnGetColor(localTipoVisita) {
      console.log(localTipoVisita);
      if (!isNaN(localTipoVisita)) {
        return this.$store.state.visitas.filter(
          (store_visita) => store_visita.id == localTipoVisita
        )[0].textColorClass;
      } else {
        return this.$store.state.OtrasVisitas.filter(
          (store_visita) => store_visita.id == localTipoVisita
        )[0].textColorClass;
      }
    },
  },
  mounted() {
    this.fnGetTblItems();
    this.flag_parametros_aplicados = true;

    const { SupervInicioDeLabores } = this.$FeathersVuex.api;
    SupervInicioDeLabores.on('created', () => {
      this.fnGetTblItems();
    });
  },
};
</script>
<style>
td:last-child {
  width: 154px;
}

/* td:nth-child(2) {
  font-size: 8px !important;
}
td:nth-child(3) {
  font-size: 8px !important;
}
td:nth-child(4) {
  font-size: 8px !important;
}
td:nth-child(5) {
  font-size: 8px !important;
}
td:nth-child(6) {
  font-size: 8px !important;
}
td:nth-child(7) {
  font-size: 8px !important;
} */
</style>
